import React, {useEffect, useRef, useState} from 'react'
import "../AdminLayout.css"
import "../../components/AdminButton.css"
import DesktopNav from "../../components/DesktopNav/DesktopNav"
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'
import emailjs from "@emailjs/browser";
import {useAuthenticator} from '@aws-amplify/ui-react';
import { useNavigate } from "react-router-dom";
import { RequireAuth } from "../../components/RequireAuth";
import AlertBox from "../../components/AlertBox/AlertBox";
import {listNewsletterLists} from "../../../graphql/queries";
import {API, graphqlOperation} from "aws-amplify";

const AdminNewsletter = () => {
    const { signOut } = useAuthenticator((context) => [context.signOut])
    const navigate = useNavigate()
    const [emailString, setEmailString] = useState(null)
    const [emailCount, setEmailCount] = useState(0)
    //The HTML code written by the Quill editor
    //const [value, setValue] = useState(null)
    //Popup state variables
    const [isSuccess, setSuccess] = useState(null)
    const [alertMessage, setMessage] = useState(null)
    const quillRef = useRef(null)
    useEffect(() => {
        API.graphql(graphqlOperation(`query {
                    listNewsletterLists { 
                        items{
                            email
                            _deleted
                        }
                    }
                }`
        )).then(res => {
            console.log(res?.data?.listNewsletterLists?.items)
            let count = 0
            let string = ""
            res?.data?.listNewsletterLists?.items.map(email => {
                if(!email._deleted){
                    count = count + 1
                    string += `${email.email};`
                }
            })
            setEmailCount(count)
            setEmailString(string)
        })
    }, [])

    /**
     * Function: createHTML
     * Purpose: Take the HTML code generated by the Quill editor and send it to emailJS to generate an email template
     *  for the newsletter email.
     */
    const createHTMLEmail = async () => {
        const editor = quillRef.current.getEditor()
        const emailHTML = quillRef.current.makeUnprivilegedEditor(editor).getHTML()
        //Check to make sure both title and body fields are filled out

        if(!document.getElementById("newsletter-title").value || !emailHTML) {
            activateAlert(false, `Please fill in both the title and the newsletter body.`)
            return
        }
        //Send the email and return a response based on success or fail.
        emailjs.send(process.env.REACT_APP_SERVICE_ID,process.env.REACT_APP_NEWSLETTER_TEMPLATE,{
            newsletter_title: document.querySelector("#newsletter-title").value,
            email_body: emailHTML,
            email_list: emailString
        }, process.env.REACT_APP_EMAIL_KEY).then((res) => {
            activateAlert(true, `Newsletter successfully sent: ${res.text}`)
            console.log(res.text)
        })
            .catch((e) => {
                activateAlert(false, `Error when creating newsletter: ${e.text}`)
                console.log(e.text)
            })
    }

    const imageHandler = async () => {
        const input = document.createElement('input');
        let quillObj = quillRef.current.getEditor();
        let image
        const range = quillObj.getSelection();
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
        input.onchange = async () => {
            let file = input && input.files ? input.files[0] : null;
            if(!file){
                return activateAlert(false, "Unable to read file.")
            }
            downscaleImage(window.URL.createObjectURL(file)).then(res => {
                image = new Image();
                image.src = res
                image.onload = () => {
                    if(image.src){
                        console.log(image)
                        document.querySelector(".ql-editor").appendChild(image)
                        //quillObj.insertEmbed(range.index, 'image', image)
                    }
                }
            }).catch(rej => console.log(rej))
        };
    }

    const downscaleImage = async (dataUrl) => {
        let image, canvas, oldHeight, oldWidth, newHeight, newWidth, ctx, newDataUrl
        newWidth = 200
        return new Promise((res, rej) => {
            image = new Image();
            image.src = dataUrl;
            image.onload = () => {
                if(!image.width) {
                    rej("Unable to resolve image.")
                }
                oldWidth = image.width;
                oldHeight = image.height;
                newHeight = Math.floor(oldHeight / oldWidth * newWidth)
                canvas = document.createElement("canvas");
                canvas.width = newWidth;
                canvas.height = newHeight;
                ctx = canvas.getContext("2d");
                ctx.drawImage(image, 0, 0, newWidth, newHeight);
                newDataUrl = canvas.toDataURL("image/jpeg", 0.5);
                res(newDataUrl)
            }
        })


    }

    /**
     * Function: activateAlert
     * Purpose: Display alert popup containing feedback to the user.
     * @param success If the alert is an error or success notice.
     * @param alertMessage The message to show to the user.
     */
    const activateAlert = (success, alertMessage) => {
        setSuccess(success)
        setMessage(alertMessage)
        setTimeout(() => {
            if(document.querySelector(".alert-box").className === "alert-box") {
                setSuccess(null)
                setMessage(null)
            }
        }, 5000)
    }

    /**
     * Function: logOut
     * Purpose: Logout and redirect page to login page.
     */
    const logOut = () => {
        signOut()
        navigate("/admin/login")
    }

    return (
        <RequireAuth>
            <div className="admin">
                <DesktopNav />
                <div id="content-main">
                    <div className="admin-header">
                        <h1>Newsletter</h1>
                        <span>Create a new newsletter here.</span>
                    </div>
                    <div id="content-block">
                        <img id="loading-icon" alt="loading" src="/assets/icons/loading.gif" />
                        <input id="newsletter-title" type="text" placeholder="Put newsletter title here..."/>
                        <ReactQuill theme="snow" ref={quillRef} modules={
                            {
                                toolbar: {
                                    container: [
                                        ['bold', 'italic', 'underline', 'strike'],
                                        [{'list': 'ordered'}, {'list': 'bullet'}],
                                        [{'indent': '-1'}, {'indent': '+1'}],
                                        [{'header': [1, 2, 3, 4, 5, 6, false]}],
                                        [{'color': []}, {'background': []}]
                                    ],
                                    'handlers': {
                                        image: imageHandler
                                    }
                                }
                            }
                        }/>
                        <button className="admin-button" onClick={() => createHTMLEmail()}>Submit Newsletter</button>
                        <div>Total Email Recipients: {emailCount - 2}</div>
                    </div>
                </div>
                <button id="btn-logout" className="admin-button" onClick={()=>logOut()}>Logout</button>
                <AlertBox isSuccess={isSuccess} alertMessage={alertMessage}/>
            </div>
        </RequireAuth>
    )
}
export default AdminNewsletter
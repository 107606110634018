import React from 'react';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import Art from './frontend/components/Art.js'
import Home from './frontend/components/Home.js'
import {QueryClient, QueryClientProvider} from "react-query";
import About from "./frontend/components/About";
import Contact from "./frontend/components/Contact";
import Events from "./frontend/components/Events";
import AdminArt from "./cms/pages/AdminArt/AdminArt"
import { ReactQueryDevtools } from "react-query/devtools";
import AdminEvent from "./cms/pages/AdminEvent/AdminEvent";
import AdminNewsletter from "./cms/pages/AdminNewsletter/AdminNewsletter";
import AdminLogin from "./cms/pages/AdminLogin";
import {Authenticator} from '@aws-amplify/ui-react'


function App() {
  const queryClient = new QueryClient();
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
          <Authenticator.Provider>
          <div className="App">
             <Routes>
                <Route path="/admin/login" element={<AdminLogin />}/>
                <Route path="/admin/events" element={<AdminEvent />}/>
                <Route path="/admin/art" element={<AdminArt />} />
                <Route path="/admin/newsletter" element={<AdminNewsletter />}/>
                <Route path="/events" element={<Events />}/>
                <Route path="/art" element={<Art />}/>
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/" element={<Home />}/>
             </Routes>
          </div>
          </Authenticator.Provider>
      </QueryClientProvider>
    </Router>
  );
}

export default App;

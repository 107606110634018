import React, {useEffect, useRef, useState} from 'react'
import "../css/Contact.css"
import Header from "./Header";
import Footer from "./Footer";
import emailjs from "@emailjs/browser"
import Banner from "./Banner";
import {Email, Phone} from "@material-ui/icons";
import AlertBox from "../../cms/components/AlertBox/AlertBox";

const Contact = () => {
    //Alert state variables
    const [isSuccess, setSuccess] = useState(null)
    const [alertMessage, setMessage] = useState(null)
    const form = useRef()
    //Highlight the Contact nav button
    useEffect(() => {
        let activeLink = document.getElementsByClassName("link-contact")
        for (let i = 0; i < activeLink.length; i++){
            activeLink[i].className += " active"
        }
    }, [])

    /**
     * Function: activateAlert
     * Purpose: Display alert popup containing feedback to the user.
     * @param success If the alert is an error or success notice.
     * @param alertMessage The message to show to the user.
     */
    const activateAlert = (success, alertMessage) => {
        setSuccess(success)
        setMessage(alertMessage)
        setTimeout(() => {
            if(document.querySelector(".alert-box").className === "alert-box") {
                setSuccess(null)
                setMessage(null)
            }
        }, 5000)
    }

    /**
     * Function: sendEmail
     * Purpose: Takes the contact form information and sends it out through an email.
     * @param e Contact form element
     */
    const sendEmail = (e) => {
        const submitButton = document.getElementById("form-submit")
        e.preventDefault()
        //Disable the submit button until the newsletter is done processing. Usually takes about 5 seconds.
        submitButton.disabled = true
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current,
            process.env.REACT_APP_EMAIL_KEY)
            .then((res) => {
                activateAlert(true, `Message successfully sent.`)
                submitButton.disabled = false
            })
            .catch((err) => {
                activateAlert(false, `Unable to send message. Please try again later.`)
                submitButton.disabled = false
            })
    }

    return (
        <div className="site">
            <Header />
            <Banner title="Contact Me" image="/assets/images/art15.jpg" />
            <div className="contact-block">
                <div className="contact-info">
                    <p><Phone /> (306)274-7555</p>
                    <p><Email /><a href="mailto:tinahorsenut@hotmail.com">tinahorsenut@hotmail.com</a></p>
                </div>
                <form ref={form} className="contact-form" onSubmit={sendEmail}>
                    <p>You can also use the form below for any questions or inquiries. Be sure to add your email and
                    name so I can get back to you!</p>
                    <input type="hidden" name="form-id" />
                    <div className="form-item">
                        <label>Name</label>
                        <input type="text" name="form-name" required={true}/>
                    </div>
                    <div className="form-item">
                        <label>Email</label>
                        <input type="email" name="form-email" required={true}/>
                    </div>
                    <div className="form-item">
                        <label>Message</label>
                        <textarea name="message" required={true}></textarea>
                    </div>
                    <div className="form-item">
                        <input id="form-submit" type="submit" value="Send" />
                    </div>

                </form>
            </div>
            <AlertBox isSuccess={isSuccess} alertMessage={alertMessage}/>
            <Footer />
        </div>
    )
}

export default Contact
import React, {useEffect} from 'react'
import '../css/Home.css'
import Header from "./Header.js"
import Footer from './Footer.js'
import Banner from "./Banner";

const getBanner = () => {
    const bannerImage = document.querySelector(".home-image")
    //Show a picture for the banner that may be more suitable for a mobile or desktop screen
    if(bannerImage) {
        //Portrait orientation preferred
        if(window.screen.width <= 768) {
            bannerImage.src = "/assets/images/Alberta_Creek.jpg"
        }
        //Landscape orientation preferred
        else {
            bannerImage.src = "/assets/images/Horses_and_Shadows.jpg"
        }
    }
}

window.addEventListener('resize', getBanner);

function Home() {
    useEffect(getBanner, [])
    return (
        <div className="site">
            <Header />
            <div className="home-block">
                <Banner image="/assets/images/Alberta_Creek.jpg" title="Welcome"/>
                <div className="home-info forward">
                    <span>
                        Whether it is the bold Arizona deserts...
                    </span>
                    <div className="home-info-image">
                        <img src="/assets/images/Cactus_Tower.jpg" alt="Homepage"/>
                    </div>
                </div>
                <div className="home-info inverse">
                    <span>
                        ...or the vast Saskatchewan plains.
                    </span>
                    <div className="home-info-image">
                        <img src="/assets/images/Sunset.jpg" alt="Homepage"/>
                    </div>
                </div>
                <div className="home-info forward">
                    <span>
                        My experiences have taken me far and wide and I hope to take you on an art-filled journey.
                    </span>
                    <div className="home-info-image">
                        <img src="/assets/images/bio2.jpg" alt="Homepage"/>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home
import React from 'react'
import "./DesktopNav.css"
import {Link} from "react-router-dom";
const DesktopNav = () => {
    return (
        <section id="desktop-nav">
            <div className="nav-section">
                <div>
                    <Link to="/admin/art" className="nav-item">Art</Link>
                    <Link to="/admin/events" className="nav-item">Events</Link>
                    <Link to="/admin/newsletter" className="nav-item">Newsletter</Link>
                </div>
            </div>
        </section>
    )
}
export default DesktopNav
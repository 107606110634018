import React, {useState, useEffect} from 'react'
import { useQuery } from "react-query"
import {API, graphqlOperation} from "aws-amplify"
import {useAuthenticator} from '@aws-amplify/ui-react';
import "../AdminLayout.css"
import "../../components/AdminButton.css"
import "../PopupLayout.css"
import DesktopNav from "../../components/DesktopNav/DesktopNav"
import { useNavigate } from "react-router-dom";
import { RequireAuth } from "../../components/RequireAuth";
import {createEvents, deleteEvents, updateEvents} from "../../../graphql/mutations";
import AlertBox from "../../components/AlertBox/AlertBox";

const AdminEvent = () => {
    const navigate = useNavigate()
    const { signOut } = useAuthenticator((context) => [context.signOut])
    //Alert state variables
    const [isSuccess, setSuccess] = useState(null)
    const [alertMessage, setMessage] = useState(null)
    //Popup state variables
    const [presetValues, setParams] = useState(null)
    const [isFirstClick, setClick] = useState(false)

    const { error, data, isFetching } = useQuery(["art"], async () => {
        const data = await API.graphql(graphqlOperation(
            `query {
                    listEvents { 
                        items{
                            location
                            address
                            archived
                            description
                            end_date
                            id
                            name
                            start_date
                            website
                            _version
                            _deleted
                        }
                    }
                }`
        ))
        console.log(data.data.listEvents)
        return data.data.listEvents
    })

    //If the data fetch returns an error, it should display an error
    useEffect(() => {
        if(error) {
            activateAlert(false, error.errors[0].message)
        }
    }, [error])

    //Website may not be rendered at this point. This is more of a render check.
    if(document.querySelector("#loading-icon")){
        //Display loading icon if query is fetching
        if(isFetching) {
            document.querySelector("#loading-icon").style.display = "block"
        } else {
            document.querySelector("#loading-icon").style.display = "none"
        }
    }

    /**
     * Function: activateNewPopup
     * Purpose: Removes information from popup and displays a new popup.
     */
    const activateNewPopup = () => {
        setParams(null)
        document.getElementById("popup-back").className = "active"
    }

    /**
     * Function: activateExistingPopup
     * Purpose: Show popup given existing information to modify.
     */
    const activateExistingPopup = (params) => {
        //Have to set params to null to that the state on the popup resets as well. Then grab the proper info to send
        //  to the popup
        setParams(null)
        setParams(params)
        document.getElementById("popup-back").className = "active"
    }

    /**
     * Function: logOut
     * Purpose: Logout and redirect page to login page.
     */
    const logOut = () => {
        signOut()
        navigate("/admin/login")
    }

    /**
     * Function: activateAlert
     * Purpose: Display alert popup containing feedback to the user.
     * @param success If the alert is an error or success notice.
     * @param alertMessage The message to show to the user.
     */
    const activateAlert = (success, alertMessage) => {
        setSuccess(success)
        setMessage(alertMessage)
        setTimeout(() => {
            if(document.querySelector(".alert-box").className === "alert-box") {
                setSuccess(null)
                setMessage(null)
            }
        }, 5000)
    }

    //Popup form functions

    /**
     * Function: closeWindow
     * Purpose: Close the popup window
     */
    const closeWindow = () => {
        document.getElementById("popup-back").className = ""
        setClick(false)
        setParams(null)
    }

    /**
     * Function: preventClose
     * Purpose: Prevent the closure of the popup when the popup window is click on in the main area.
     * @param e Event handler of the popup
     */
    const preventClose = (e) => {
        e.stopPropagation()
    }

    /**
     * Function: modifyEvent
     * Purpose: Add an Event object or modify an existing Event object
     * @param e Event handler for the form
     */
    const modifyEvent = async (e) => {
        e.preventDefault()
        const eventInfo = {
            name: e.target[0].value,
            start_date: (e.target[1].value ? e.target[1].value + "T06:00:00Z" : undefined),
            end_date: (e.target[2].value ? e.target[2].value + "T06:00:00Z" : undefined),
            location: e.target[3].value,
            address: e.target[4].value,
            website: e.target[5].value,
            archived: e.target[6].checked
        }

        //If id exists, it means the object is to be modified. Otherwise no id mean it doesn't exist yet and needs to be
        //  created.
        if(presetValues?.id){
            try{
                await API.graphql({ query: updateEvents, variables: {input: {
                            id: presetValues.id, _version: presetValues?._version, ...eventInfo}}})
                activateAlert(true, "Successfully updated event info.")
            } catch (e){
                console.log(e)
                activateAlert(false, `Error on updating event info: ${e.errors[0].message}`)
            }
        } else {
            try{
                await API.graphql({ query: createEvents, variables: {input: {...eventInfo}}})
                activateAlert(true, "Successfully created event object.")
            } catch (e){
                console.log(e)
                activateAlert(false, `Error creating event: ${e.errors[0].message}`)
            }
        }
        closeWindow()
    }

    /**
     * Function: deleteEntry
     * Purpose: Delete an existing entry
     */
    const deleteEntry = async () => {
        if(!isFirstClick) {
            setClick(true)
        } else {
            try {
                await API.graphql({query: deleteEvents, variables:{input:{id: presetValues.id, _version: presetValues._version}}})
                activateAlert(true, `Successfully deleted event.`)
            } catch (e) {
                activateAlert(false, `Error when deleting event: ${e.errors[0].message}`)
                console.log(e)
            }
            setClick(false)
            closeWindow()
        }
    }

    return(
        <RequireAuth>
            <div className="admin">
                <DesktopNav />
                <div id="popup-back" onClick={() => closeWindow()}>
                    <div id="popup-main" onClick={(e) => preventClose(e)}>
                        <form id="admin-event-form" onSubmit={modifyEvent}>
                            <span id="form-id">{presetValues?.id ? `ID: ${presetValues.id}` : ""}</span>
                            <div id="form-top">
                                <label htmlFor="form-name">Event Name<span className="required">*</span></label>
                                <input id="form-name" name="form-name" type="text"
                                       defaultValue={presetValues?.name || undefined} required={true}/>
                                <label htmlFor="form-start">Start Date<span className="required">*</span></label>
                                <input id="form-start" name="form-start" type="date"
                                       defaultValue={presetValues?.start_date?.substring(0, 10) || undefined}
                                       required={true}/>
                                <label htmlFor="form-end">End Date</label>
                                <input id="form-end" name="form-end" type="date"
                                       defaultValue={presetValues?.end_date?.substring(0, 10) || undefined}/>
                                <label htmlFor="form-location">Event Location</label>
                                <input id="form-location" name="form-location" type="text"
                                       defaultValue={presetValues?.location || undefined} required={true}/>
                                <label htmlFor="form-address">Address</label>
                                <input id="form-address" name="form-address" type="text"
                                       defaultValue={presetValues?.address || undefined} required={true}/>
                                <label htmlFor="form-website">Event Website Link</label>
                                <input id="form-website" name="form-website" type="url"
                                       placeholder="e.g. https://www.google.ca"
                                       defaultValue={presetValues?.website || undefined}/>
                                <label htmlFor="form-archived">Archive Event (checked = yes, unchecked = no)
                                    <span className="required">*</span></label>
                                <input id="form-archived" name="form-archived" type="checkbox" aria-required="true"
                                       defaultChecked={presetValues?.archived}/>
                            </div>
                            <span id="form-notice">{isFirstClick ? "Click Delete again to confirm removal" : ""}</span>
                            <div id="form-bottom">
                                <input type="submit" className="admin-button"/>
                                <button type="button" className="admin-button"
                                        onClick={() => deleteEntry()} disabled={!presetValues}>Delete this Entry
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
                <div id="content-main">
                    <div className="admin-header">
                        <h1>Event</h1>
                        <span>Add new Events and adjust/archive existing Events.</span>
                    </div>
                    <div id="table-block">
                        <img id="loading-icon" alt="loading" src="/assets/icons/loading.gif" />
                        <div id="table-header">
                            <button className="admin-button" onClick={() => activateNewPopup()}>New Entry</button>
                        </div>
                        <table>
                            <thead>
                            <tr>
                                <th data-type="text-short">ID</th>
                                <th data-type="text-short">Event Name</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data?.items?.map(event => (
                                event._deleted ? "" : (
                                    <tr key={event.id}>
                                        <td onClick={() => activateExistingPopup(event)}>{event.id}</td>
                                        <td>{event.name}</td>
                                    </tr>
                                )
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <button id="btn-logout" className="admin-button" onClick={()=>logOut()}>Logout</button>
                <AlertBox isSuccess={isSuccess} alertMessage={alertMessage}/>
            </div>
        </RequireAuth>
    )
}

export default AdminEvent
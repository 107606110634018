import React, {useState, useEffect, useRef} from 'react'
import { useQuery } from "react-query"
import {API, graphqlOperation, Storage} from "aws-amplify"
import {useAuthenticator} from '@aws-amplify/ui-react';
import "../AdminLayout.css"
import "../../components/AdminButton.css"
import "../PopupLayout.css"
import { useNavigate } from 'react-router-dom';
import DesktopNav from "../../components/DesktopNav/DesktopNav"
import {RequireAuth} from "../../components/RequireAuth";
import AlertBox from "../../components/AlertBox/AlertBox";
import {createArt, deleteArt, updateArt} from "../../../graphql/mutations";
const AdminArt = () => {
    const navigate = useNavigate()
    const { signOut } = useAuthenticator((context) => [context.signOut])
    //Alert state variables
    const [isSuccess, setSuccess] = useState(null)
    const [alertMessage, setMessage] = useState(null)
    //Popup state variables
    const [isFirstClick, setClick] = useState(false)
    const [presetValues, setParams] = useState(null)
    const [artImages, setImages] = useState({})

    const { error, data, isFetching } = useQuery(["art"], async () => {
        const data = await API.graphql(graphqlOperation(
            `query {
                    listArts { 
                        items{
                            id
                            title
                            image_url
                            price
                            frame
                            archived
                            dimensions {
                                width
                                height
                            }
                            paint
                            _deleted
                            _version
                        }
                    }
                }`
        ))
        return data.data.listArts
    })

    useEffect(() => {
        console.log(data)
        const imageList = {}
        data?.items?.map((image) => {
            Storage.get(`images/${image.image_url}`).then(result => {
                imageList[image.image_url] = result
            })
        })
        console.log(imageList)
        setImages(imageList)
    }, [data])

    //If the data fetch returns an error, it should display an error
    useEffect(() => {
        if(error) {
            activateAlert(false, error.errors[0].message)
        }
    }, [error])

    //Website may not be rendered at this point. This is more of a render check.
    if(document.querySelector("#loading-icon")){
        //Display loading icon if query is fetching
        if(isFetching) {
            document.querySelector("#loading-icon").style.display = "block"
        } else {
            document.querySelector("#loading-icon").style.display = "none"
        }
    }

    /**
     * Function: activateNewPopup
     * Purpose: Removes information from popup and displays a new popup.
     */
    const activateNewPopup = () => {
        setParams(null)
        document.getElementById("popup-back").className = "active"
    }

    /**
     * Function: activateExistingPopup
     * Purpose: Show popup given existing information to modify.
     */
    const activateExistingPopup = (params) => {
        setParams(params)
        document.getElementById("popup-back").className = "active"
    }

    /**
     * Function: logOut
     * Purpose: Logout and redirect page to login page.
     */
    const logOut = () => {
        signOut()
        navigate("/admin/login")
    }

    /**
     * Function: activateAlert
     * Purpose: Display alert popup containing feedback to the user.
     * @param success If the alert is an error or success notice.
     * @param alertMessage The message to show to the user.
     */
    const activateAlert = (success, alertMessage) => {
        setSuccess(success)
        setMessage(alertMessage)
        //After 5 seconds, remove the alert and reset the alert box.
        setTimeout(() => {
            if(document.querySelector(".alert-box").className === "alert-box") {
                setSuccess(null)
                setMessage(null)
            }
        }, 5000)
    }

    //Popup form functions

    /**
     * Function: closeWindow
     * Purpose: Close the popup window
     */
    const closeWindow = () => {
        document.getElementById("popup-back").className = ""
        setClick(false)
    }

    /**
     * Function: preventClose
     * Purpose: Prevent the closure of the popup when the popup window is click on in the main area.
     * @param e Event handler of the popup
     */
    const preventClose = (e) => {
        e.stopPropagation()
    }

    /**
     * Function: modifyArt
     * Purpose: Add an Art object or modify an existing Art object
     * @param e Event handler for the form
     */
    const modifyArt = async (e) => {
        e.preventDefault()
        const file = e.target[1].files[0]
        const artInfo = {
            title: e.target[0].value,
            price: parseFloat(e.target[2].value),
            paint: e.target[3].value,
            frame: e.target[4].checked,
            dimensions: {
                width: parseInt(e.target[5].value),
                height: parseInt(e.target[6].value)
            },
            archived: e.target[7].checked
        }
        //If a file was inserted. Grab the name of it and add it to the artInfo object. Otherwise, it should be ignored
        //  to prevent an overwrite of the file when there shouldn't be.
        if(file){
            artInfo["image_url"] = file.name
        }
        //If id exists, it means the object is to be modified. Otherwise no id mean it doesn't exist yet and needs to be
        //  created.
        if(presetValues?.id){
            try{
                await API.graphql({ query: updateArt, variables: {input: {
                            id: presetValues.id, _version: presetValues?._version, ...artInfo}}})
                activateAlert(true, "Successfully updated art info.")
            } catch (e){
                console.log(e)
                activateAlert(false, `Unable to update art info: ${e.errors[0].message}`)
            }
        } else {
            try{
                await API.graphql({ query: createArt, variables: {input: {...artInfo}}})
                activateAlert(true, "Successfully created art information.")
            } catch (e){
                activateAlert(false, `Unable to create art object: ${e.errors[0].message}`)
                console.log(e.errors[0].message)
            }
        }
        //Insert the file into the AWS S3 storage.
        //IMPORTANT: Conflicting file names will cause an overwrite!
        if(file){
            try {
                Storage.put(`images/${file.name}`, file).then(res => {
                    console.log("uploaded " + file.name)
                })

            } catch (error) {
                console.log("Error uploading file: ", error);
            }
        }
        closeWindow()
    }

    /**
     * Function: deleteEntry
     * Purpose: Delete an existing entry
     */
    const deleteEntry = async () => {
        //Delete must be clicked twice as a means to confirm deletion. First click activates a switch to confirm that
        //  a click was recognized.
        if(!isFirstClick) {
            setClick(true)
        } else {
            //Once a second click is recognized, try to delete the entry.
            try {
                await API.graphql({query: deleteArt, variables:{input:{id: presetValues.id, _version: presetValues._version}}})
                activateAlert(true, "Successfully deleted art entry.")
            } catch (e) {
                console.log(e)
                activateAlert(false, `Unable to delete art: ${e.errors[0].message}`)
            }
            setClick(false)
            closeWindow()
        }
    }

    return(
        <RequireAuth>
            <div className="admin">
                <div id="popup-back" onClick={() => closeWindow()}>
                    <div id="popup-main" onClick={(e) => preventClose(e)}>
                        <form id="admin-art-form" onSubmit={modifyArt}>
                            <span id="form-id">{presetValues?.id ? `ID: ${presetValues.id}` : ""}</span>
                            <div id="form-top">
                                <label htmlFor="form-name">Art Name</label>
                                <input id="form-name" name="form-name" type="text"
                                       defaultValue={presetValues?.title || undefined}/>
                                <label htmlFor="form-image">Upload Art Image<span className="required">*</span></label>
                                <input id="form-image" name="form-image" type="file" accept="image/*"
                                       aria-required="true"/>
                                {presetValues?.image_url ? <span>Existing image: {presetValues.image_url}</span> : ""}
                                <label htmlFor="form-price">Cost ($)</label>
                                <input id="form-price" name="form-price" type="number"
                                       defaultValue={presetValues?.price || undefined}/>
                                <label htmlFor="form-paint">Paint Type Used (ex: Oil on Canvas)</label>
                                <input id="form-paint" name="form-paint" type="text"
                                       defaultValue={presetValues?.paint || undefined}/>
                                <label htmlFor="form-framed">Does This Art Have a Frame? (checked = yes, unchecked =
                                    no)<span className="required">*</span></label>
                                <input id="form-framed" name="form-framed" type="checkbox" aria-required="true"
                                       defaultChecked={presetValues?.frame}/>
                                <label htmlFor="form-width">Dimensions</label>
                                <div id="form-dimensions">
                                    <input id="form-width" name="form-width" type="number" placeholder="width"
                                           defaultValue={presetValues?.dimensions?.width || undefined}/> <i>"</i>
                                    <strong>x</strong>
                                    <input id="form-height" name="form-height" type="number" placeholder="height"
                                           defaultValue={presetValues?.dimensions?.height || undefined}/> <i>"</i>
                                </div>
                                <label htmlFor="form-archived">Is This Art Sold? (checked = yes, unchecked = no)
                                    <span className="required">*</span></label>
                                <input id="form-archived" name="form-archived" type="checkbox" aria-required="true"
                                       defaultChecked={presetValues?.archived}/>
                            </div>
                            <span id="form-notice">{isFirstClick ? "Click Delete again to confirm removal" : ""}</span>
                            <div id="form-bottom">
                                <input type="submit" className="admin-button"/>
                                <button type="button" className="admin-button" onClick={() => deleteEntry()}
                                        disabled={!presetValues}>Delete this Entry
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <DesktopNav />
                <div id="content-main">
                    <div className="admin-header">
                        <h1>Art</h1>
                        <span>Create, edit and remove all your art here.</span>
                    </div>
                    <div id="table-block">
                        <img id="loading-icon" alt="loading" src="/assets/icons/loading.gif" />
                        <div id="table-header">
                            <button  className="admin-button" onClick={() => activateNewPopup()}>New Entry</button>
                        </div>
                        <table>
                            <thead>
                            <tr>
                                <th data-type="text-short">ID</th>
                                <th data-type="text-short">Art Name</th>
                                <th data-type="text-short">Image Name</th>
                                <th data-type="text-short">Image</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data?.items?.map(art => (
                                art._deleted ? "" : (
                                    <tr key={art.id}>
                                        <td onClick={() => activateExistingPopup(art)}>{art.id}</td>
                                        <td>{art.title ? art.title : "-"}</td>
                                        <td>{art.image_url}</td>
                                        <td className="image-cell"><img src={art?.image_url in artImages ?
                                            artImages[art?.image_url] : ""}
                                            alt="mini-art"/></td>
                                    </tr>
                                )
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <button id="btn-logout" className="admin-button" onClick={()=>logOut()}>Logout</button>
                <AlertBox isSuccess={isSuccess} alertMessage={alertMessage}/>
            </div>
        </RequireAuth>
    )
}

export default AdminArt
import React, {useEffect} from 'react'
import "../css/About.css"
import Header from "./Header"
import Footer from "./Footer"
import Banner from "./Banner";

const About = () => {
    //Highlight the About nav tab
    useEffect(() => {
        let activeLink = document.getElementsByClassName("link-about")
        for (let i = 0; i < activeLink.length; i++){
            activeLink[i].className += " active"
        }
    }, [])
    return (
        <div className="site">
            <Header />
            <div className="about-block">
                <Banner title="About Me" image="/assets/images/art8.jpg"/>
                <div className="about-top">
                    <img src="/assets/images/bio_photo.jpg" alt="bio"/>
                    <div className="bio-block">
                        <img src="/assets/images/art9.jpg" alt="cow"/>
                        <p>
                            Tina (b. 1968) was raised in central Saskatchewan on a small farm where she has long been influenced by the experiences that have come with
                            the farm life. It could be the flat plains of wheat that grew throughout the summertime or the vast cattle that grew up around her. She is no
                            stranger to art, having drawn things since she could hold a pencil. When Tina was 18, she was taught how to use oil paintings by an experienced painter in which she found joy in creating art. While Tina has had
                            influence and inspiration from watching other artists do their work, her work is very much her own.
                        </p>
                    </div>
                    <div className="bio-block">
                        <p>
                            Tina's drive for her outstanding work comes from her visualization of her surroundings. She has a great appreciation for the vast landscapes
                            of where the area she resides around, whether it is the luscious plains of Saskatchewan in the summertime, or the bold deserts of Arizona in
                            the wintertime. While most of her painting is done on canvas, Tina has been known to present her art in unique ways that bring out the most in
                            her personality. It could be using barn board as a picture frame, or fully engulfing a swather sickle in some beautiful farm land. Through such
                            unique techniques, she is able to gain the attention of many people, both young and old and especially those who live in and appreciate the rural
                            lifestyle.
                        </p>
                    </div>
                    <div className="bio-block">
                        <p>
                            On a daily basis, Tina currently lives the perfect balance of operating a ranch with her husband Cody and painting her vast art projects. She has contributed
                            to many galleries in the past and continues to this day displaying her work at many events in the local area and participating with local art guilds.
                        </p>
                    </div>
                    <img src="/assets/images/bio3.jpg" className="about-image-bottom" alt="bio"/>

                </div>
            </div>
            <Footer />
        </div>
    )
}
 export default About
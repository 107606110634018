import React, {useEffect} from 'react'
import {Authenticator, useAuthenticator, View} from '@aws-amplify/ui-react'
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css'
import {useLocation, useNavigate} from "react-router-dom";

/**
 * Function: AdminLogin
 * Purpose: Page to login to the other admin pages.
 */
const AdminLogin = () => {
    const { route } = useAuthenticator((context) => [context.route]);
    const location = useLocation();
    const navigate = useNavigate();
    //Go back to previous page that was denied from or /admin/art if accessed the login page directly on authentication
    let from = location.state?.from?.pathname || '/admin/art';
    useEffect(() => {
        if (route === 'authenticated') {
            navigate(from, { replace: true });
        }
    }, [route, navigate, from]);
    return (
        <View>
            <Authenticator hideSignUp={true}></Authenticator>
        </View>

    )
}

export default AdminLogin
import React from 'react'
import '../css/Header.css'
import {Link} from "react-router-dom";
import MobileNav from './MobileNav'
import {Menu} from "@material-ui/icons";

function Header() {

    /**
     * Function: showMenu
     * Purpose: Add class to nav to make nav appear.
     */
    const showMenu = () => {
        const menuEvent = document.querySelector("#mobile-nav-back")
        if(menuEvent) {
            menuEvent.className = "active"
        }
    }

    return (
        <div className="header-block">
            <MobileNav/>
            <div className="header-left">
                <Link to="/art" className="link-art">Art</Link>
                <Link to="/about" className="link-about">About</Link>
                <Link to="/events" className="link-events">Events</Link>
                <Link to="/contact" className="link-contact">Contact</Link>
            </div>
            <div className="mobile-left" onClick={() => showMenu()}>
                <Menu />
            </div>
            <div className="header-right">
                <Link to="/"><h1>Tina Waters</h1></Link>
            </div>
            <img id="loading-icon" src="/assets/icons/loading.gif" alt="loading"/>
        </div>
    )
}

export default Header
import React, {useEffect, useState} from 'react'
import "./AlertBox.css"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const AlertBox = (props) => {
    const [title, setTitle] = useState("")
    const [isVisible, setVisibility] = useState(false)
    const [timeoutID, setTimeoutID] = useState(null)
    const [info, setInfo] = useState(null)

    useEffect(() => {
        if(props) {
            setInfo(props)
        }
    }, [props])

    useEffect(() => {
        /**
            If isVisible is true, activate css based on the type of alert.
            -"success" = green w/ checkmark
            -"error" = red w/ cross
         */
        if(isVisible) {
            if(info.isSuccess === true) {
                document.querySelector(".alert-box").className = "alert-box active success"
            } else if(info.isSuccess === false) {
                document.querySelector(".alert-box").className = "alert-box active error"
            } else {
                return null
            }
            //The alert window is on a timer. If there is an exising alert active, reset the timer and display the
            //  new alert.
            clearTimeout(timeoutID)
            setTimeoutID(setTimeout(() => {
                setVisibility(false)
                setInfo(null)
            }, 4000))
        } else {
            document.querySelector(".alert-box").className = "alert-box"
        }
    }, [isVisible])

    useEffect(() => {
        if(info){
            // info.isSuccess could be undefined. Therefore !info.isSuccess cant be utilized as undefined would
            //  make it true.
            if(info.isSuccess === true || info.isSuccess === false) {
                if(info.isSuccess === true){
                    setTitle("Success")
                } else if(info.isSuccess === false) {
                    setTitle("Error")
                }
                setVisibility(true)
            }
        }
    }, [info])

    return (
        <div className="alert-box">
            <div>
                <div className="alert-top">
                    {props.isSuccess ? <CheckCircleIcon /> : <CancelIcon />}
                    <span className="alert-head">{title}</span>
                </div>

                <span className="alert-body">{props.alertMessage}</span>
            </div>
        </div>
    )
}

export default AlertBox
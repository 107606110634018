import React, {useState} from 'react'
import '../css/Footer.css'
import {Facebook, Instagram} from "@material-ui/icons";
import {API, graphqlOperation} from "aws-amplify";
import AlertBox from "../../cms/components/AlertBox/AlertBox";

function Footer() {
    //Alert state variables
    const [isSuccess, setSuccess] = useState(null)
    const [alertMessage, setMessage] = useState(null)

    /**
     * Function: activateAlert
     * Purpose: Display alert popup containing feedback to the user.
     * @param success If the alert is an error or success notice.
     * @param alertMessage The message to show to the user.
     */
    const activateAlert = (success, alertMessage) => {
        setSuccess(success)
        setMessage(alertMessage)
        setTimeout(() => {
            if(document.querySelector(".alert-box").className === "alert-box") {
                setSuccess(null)
                setMessage(null)
            }
        }, 5000)
    }

    /**
     * Function: handleEmail
     * Purpose: Take the email supplied by the user and add it to a list.
     * @param e Email text input element
     */
    const handleEmail = async (e) => {
        e.preventDefault()
        //Email given
        const formEmail = document.getElementById("newsletter-email").value
        //Subscribe/unsubscribe checkbox
        const isChecked = document.getElementById("newsletter-check").checked
        let mutation
        try {
            /**
             * Grabbing the email id for 2 potential reasons:
             * -To delete an entry if it exists
             * -To prevent duplicate entries into the database by checking if an id exists already
             */
            const emailFetch =  `
                    query {
                        listNewsletterLists(filter: {email: {eq: "${formEmail}"}}) {
                            items {
                                id
                                _version
                            }
                        }
                    }
                `
            const data = await API.graphql(graphqlOperation(emailFetch))
            const result = data?.data?.listNewsletterLists?.items[0]?.id
            //Need version number otherwise "Conflict resolver rejects mutation." error
            //https://stackoverflow.com/questions/65395564/error-conflict-resolver-rejects-mutation-when-delete-in-amplify
            const version = data?.data?.listNewsletterLists?.items[0]?._version
            if(isChecked) {
                if(result){
                    mutation = `
                    mutation {
                        deleteNewsletterList(input: {id: "${result}", _version: ${version}}) {
                            id
                            email
                        }
                    }
                `
                }
            } else {
                if(!result) {
                    //Otherwise add the email
                    mutation = `
                    mutation {
                        createNewsletterList(input: {email:"${formEmail}"}){
                            id
                            email
                        }
                    }
                `
                } else{
                    console.log("No action executed.")
                    return
                }
            }
            await API.graphql(graphqlOperation(mutation))
            if(isChecked) {
                activateAlert(true, `Email successfully removed.`)
            } else {
                activateAlert(true, `Email added. Thank you!`)
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="footer-block">
            <div className="footer-left">
                <form className="newsletter-form" onSubmit={handleEmail}>
                    <h2>Subscribe to my Newsletter</h2>
                    <input type="email" placeholder="Enter your email here" id="newsletter-email" />
                    <div className="form-bottom">
                        <div>
                            <label>Unsubscribe:</label>
                            <input type="checkbox" id="newsletter-check"/>
                        </div>
                        <button id="form-submit" type="submit" >Submit</button>
                    </div>
                </form>
            </div>
            <div className="footer-right">
                <a href="https://www.instagram.com/tinawatersartist/" className="social-link">
                    <Instagram />
                    <span>Instagram</span>
                </a>
                <a href="https://www.facebook.com/tinawatersartist" className="social-link">
                    <Facebook />
                    <span>Facebook</span>
                </a>
            </div>
            <AlertBox isSuccess={isSuccess} alertMessage={alertMessage}/>
        </div>
    )
}

export default Footer
import React from 'react'
import "../css/Banner.css"

const Banner = (props) =>{
    return(
        <div id="banner" style={{ backgroundImage: "url(" + props.image + ")" }}>
            <h1>{props.title}</h1>
        </div>
    )
}
export default Banner
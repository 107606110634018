import React, {useState, useEffect, useRef, useCallback} from 'react'
import { useQuery } from "react-query"
import "../css/Art.css"
import {Auth, API, graphqlOperation, Storage } from "aws-amplify"
import Header from "./Header"
import Footer from "./Footer"
import Banner from "./Banner"
import LightGallery from 'lightgallery/react'
import 'lightgallery/css/lightgallery.css'

const Art = () => {
    const gallery = useRef(null)
    const [showSold, setSoldState] = useState(true)
    const [imageURLs, setImageURLs] = useState({})

    //Highlight the Art nav tab
    useEffect(() => {
        let activeLink = document.getElementsByClassName("link-art")
        for (let i = 0; i < activeLink.length; i++){
            activeLink[i].className += " active"
        }
    }, [])
    //The default value of setSoldState is supposed to be false. It is initialized as true so that
    //  ReactQuery renders the information for the "sold" tab ahead of time.
    useEffect(() => {setSoldState(false)}, [])
    const { status, error, data, isFetching } = useQuery(["art", showSold], async () => {
        const data = await API.graphql(graphqlOperation(
                `query {
                    listArts(filter: {archived: {eq: ${showSold}}}) { 
                        items{
                            id
                            title
                            image_url
                            price
                            frame
                            dimensions {
                                width
                                height
                            }
                            paint
                            _deleted
                        }
                    }
                }`
            ))

        return data
    })

    /**
     * Function: getURLData
     * Purpose: Parse the image name and grab it from Amazon S3
     */
    const getURLData = () => {
        let urlCount = 0
        const newURLs = {}
        data?.data?.listArts?.items.map(res => {
            //If the image was successfully fetched, then add the successful image name to an object that contains all
            //  successful images.
            Storage.get(`images/${res.image_url}`, {level: "public"}).then(result => {
                newURLs[res.image_url] = result
                urlCount++
                if(urlCount === data?.data?.listArts?.items.length) {
                    setImageURLs(newURLs)
                }
            })
        })

    }

    useEffect(() => {
        if(data?.data?.listArts?.items.length > 0){
            getURLData()
        }
    }, [data])

    //LightGallery will not update if there are dynamic changes to the DOM. If new images were added, refresh
    useEffect(() => {
        if(Object.keys(imageURLs).length > 0) {
            gallery?.current?.refresh();
        }
    }, [imageURLs])

    const initGallery = useCallback((detail) => {
        if (detail) {
            gallery.current = detail.instance
        }
    }, [])

    /**
     * @function swapTabs
     * @param buttonState A boolean to determine which tabs get what style and what information to grab.
     */
    const swapTabs = (buttonState) => {
        if(buttonState) {
            document.querySelector("#btn-sold").className = "active"
            document.querySelector("#btn-sale").className = ""
        } else {
            document.querySelector("#btn-sale").className = "active"
            document.querySelector("#btn-sold").className = ""
        }
        //Change the sold state so the new data can get properly queried.
        setSoldState(buttonState)
    }

    //Website may not be rendered at this point. This is more of a render check.
    if(document.querySelector("#loading-icon")){
        //Display loading icon if query is fetching
        if(isFetching) {
            document.querySelector("#loading-icon").style.display = "block"
        } else {
            document.querySelector("#loading-icon").style.display = "none"
        }
    }


    if(status === "loading") {
        return (
            <div>
                <Header />
                <span>Loading...</span>
                <Footer/>
            </div>
        )
    }
    else if(status === "error") {
        return <span>Error: {error.message}</span>
    }

    return(
                <div className="site">
                    <Header />
                    <Banner title="Gallery" image="/assets/images/Sunset.jpg" />
                    <div className="art-block">
                        <div className="art-nav">
                            <button id="btn-sale" className="active" onClick={() => swapTabs(false)}>Art for Sale</button>
                            <button id="btn-sold" onClick={() => swapTabs(true)}>Previous Works</button>
                        </div>
                        <LightGallery
                            elementClassNames="list-container"
                            onInit={initGallery}
                        >
                            {!showSold ? data?.data?.listArts?.items?.map(art => (
                                //_deleted is a variable that gets flagged as true when an item gets removed on
                                //  the AWS Amplify console. Worth checking variable in case the console gets used.
                                art._deleted ? "" : (
                                    <div className="list-object sale-item" key={art.id} href={imageURLs[art.image_url] ||
                                    "/assets/icons/loading.gif"}>
                                        <img className="list-image" src={imageURLs[art.image_url]} loading="lazy"
                                             alt={(art.title ? `| ${art.title} | ` : "| Unnamed Art | ") +
                                             (art.price ? `$${art.price} | ` : "") +
                                             (art.dimensions.width && art.dimensions.height ? `${art.dimensions.height}" 
                                             x ${art.dimensions.width}" | ` : "") +
                                             (art.paint ? `${art.paint} | ` : "") +
                                             (art.frame ? "Framed | " : "")}
                                        />
                                        <div className="middle-info">
                                            <span>{art.title || ""}</span>
                                            <span>{art.dimensions.width && art.dimensions.height ?
                                                `${art.dimensions.height}" x ${art.dimensions.width}"` : ""}</span>
                                            <span>{art.price ? "$" : ""}{art.price}</span>
                                        </div>
                                    </div>)
                            )):data?.data?.listArts?.items?.map(art => (
                                <div className="list-object" key={art.id} href={imageURLs[art.image_url] ||
                                "/assets/icons/loading.gif"}>
                                    <img className="list-image" src={imageURLs[art.image_url]}
                                         alt=" " loading="lazy"/>
                                </div>
                            ))}
                        </LightGallery>
                    </div>
                    <Footer />
                </div>
    )
}

export default Art
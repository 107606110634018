/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createArt = /* GraphQL */ `
  mutation CreateArt(
    $input: CreateArtInput!
    $condition: ModelArtConditionInput
  ) {
    createArt(input: $input, condition: $condition) {
      id
      title
      image_url
      frame
      price
      dimensions {
        width
        height
      }
      archived
      paint
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateArt = /* GraphQL */ `
  mutation UpdateArt(
    $input: UpdateArtInput!
    $condition: ModelArtConditionInput
  ) {
    updateArt(input: $input, condition: $condition) {
      id
      title
      image_url
      frame
      price
      dimensions {
        width
        height
      }
      archived
      paint
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteArt = /* GraphQL */ `
  mutation DeleteArt(
    $input: DeleteArtInput!
    $condition: ModelArtConditionInput
  ) {
    deleteArt(input: $input, condition: $condition) {
      id
      title
      image_url
      frame
      price
      dimensions {
        width
        height
      }
      archived
      paint
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createEvents = /* GraphQL */ `
  mutation CreateEvents(
    $input: CreateEventsInput!
    $condition: ModelEventsConditionInput
  ) {
    createEvents(input: $input, condition: $condition) {
      id
      name
      start_date
      end_date
      location
      address
      website
      archived
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateEvents = /* GraphQL */ `
  mutation UpdateEvents(
    $input: UpdateEventsInput!
    $condition: ModelEventsConditionInput
  ) {
    updateEvents(input: $input, condition: $condition) {
      id
      name
      start_date
      end_date
      location
      address
      website
      archived
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteEvents = /* GraphQL */ `
  mutation DeleteEvents(
    $input: DeleteEventsInput!
    $condition: ModelEventsConditionInput
  ) {
    deleteEvents(input: $input, condition: $condition) {
      id
      name
      start_date
      end_date
      location
      address
      website
      archived
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createNewsletterList = /* GraphQL */ `
  mutation CreateNewsletterList(
    $input: CreateNewsletterListInput!
    $condition: ModelNewsletterListConditionInput
  ) {
    createNewsletterList(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateNewsletterList = /* GraphQL */ `
  mutation UpdateNewsletterList(
    $input: UpdateNewsletterListInput!
    $condition: ModelNewsletterListConditionInput
  ) {
    updateNewsletterList(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteNewsletterList = /* GraphQL */ `
  mutation DeleteNewsletterList(
    $input: DeleteNewsletterListInput!
    $condition: ModelNewsletterListConditionInput
  ) {
    deleteNewsletterList(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

import React from 'react'
import '../css/MobileNav.css'
import {Link} from 'react-router-dom'

/**
 * TODO:
 * -Add slide-in animation on click
 * -Add slide-out animation on off-click
 * -Improve styling on nav
 */

const MobileNav = () => {
    //Remove class that makes nav visible on clicking the background of the nav.
    const hideNav = () => {
        document.querySelector("#mobile-nav-back").className = ""
    }
    return (
        <div id="mobile-nav-back" onClick={() => hideNav()}>
            <div id="mobile-nav">
                <div id="nav-top">
                    <Link to="/art" className="link-art">Art</Link>
                    <Link to="/about" className="link-about">About</Link>
                    <Link to="/events" className="link-events">Events</Link>
                    <Link to="/contact" className="link-contact">Contact</Link>
                </div>
                <div id="nav-bottom">
                    <Link to="/" className="link-home"><h1>Tina Waters</h1></Link>
                </div>

            </div>
        </div>
    )
}


export default MobileNav
import React, {useState, useEffect} from 'react'
import "../css/Events.css"
import {useQuery} from "react-query";
import Header from "./Header";
import Footer from "./Footer";
import Banner from "./Banner";
import {API, graphqlOperation} from "aws-amplify";
/**
 * TODO:
 * -fix URL issue (https)
 */
const Events = () =>{
    const [archived, setArchived] = useState(false);

    //Highlight the Events nav button
    useEffect(() => {
        let activeLink = document.getElementsByClassName("link-events")
        for (let i = 0; i < activeLink.length; i++){
            activeLink[i].className += " active"
        }
    }, [])

    const { status, error, data, isFetching } = useQuery(["events", archived], async () => {
        const data = await API.graphql(graphqlOperation(`
            query MyQuery {
              listEvents(filter: {archived: {eq: ${archived}}}
              ) {
                items {
                  location
                  address
                  archived
                  description
                  end_date
                  id
                  name
                  start_date
                  website
                  _deleted
                }
              }
            }
        `
        ))
        return data
    })

    if(status === "error") {
        return (
            <div>
                <Header />
                <span>Error: {error.message}</span>
                <Footer/>
            </div>
        )
    }

    //Website may not be rendered at this point
    if(document.querySelector("#loading-icon")){
        //Display loading icon if query is fetching
        if(isFetching) {
            document.querySelector("#loading-icon").style.display = "block"
        } else {
            document.querySelector("#loading-icon").style.display = "none"
        }
    }

    /**
     * Function: convertDate
     * Purpose: Builds a readable date string based on the dates passed in
     * @param startDate First day to be converted
     * @param endDate Last day to be converted (can be undefined)
     * @returns {string} String of date for show.
     */
    const convertDate = (startDate, endDate) => {
        const start = new Date(startDate)
        const end = new Date(endDate)
        const months = {
            0: 'January',
            1: 'February',
            2: 'March',
            3: 'April',
            4: 'May',
            5: 'June',
            6: 'July',
            7: 'August',
            8: 'September',
            9: 'October',
            10: 'November',
            11: 'December'
        }
        //Build string based on if an end date was given
        if (endDate) {
            const date = `${months[start.getMonth()]} ${start.getDate()},${start.getFullYear()}
        - ${months[end.getMonth()]} ${end.getDate()},${end.getFullYear()}`
            return date
        }
        return `${months[start.getMonth()]} ${start.getDate()},${start.getFullYear()}`
    }


    return (
        <div className="site">
            <Header />
            <Banner title="Events" image="/assets/images/art24.jpg"/>
            <div className="events-block">
                {data?.data?.listEvents?.items.length > 0 ? data?.data?.listEvents?.items.map(event => (
                    event._deleted ? "" : (
                        <div key={event.id} className="event-item">
                            <h2>{event.name}</h2>
                            <p><strong>Event Date: </strong>{convertDate(event.start_date, event.end_date)}</p>
                            <p><strong>Address: </strong>{event.address}</p>
                            <p><strong>Location: </strong>{event.location}</p>
                            {event.website ? <p><strong>Website: </strong><a target="_blank" rel="noreferrer"
                                                            href={event.website}>{event.website}</a></p> : ""}
                            <p>{event.description}</p>
                        </div>)
                )) :
                    <div className="event-item">
                        <h2>There are no events to show.</h2>
                    </div>
                }
                <button onClick={() => setArchived(!archived)} href="#banner">{!archived ? "Click here to see past events" :
                    "Click here to see the upcoming events"}</button>
            </div>
            <Footer />
        </div>
    )
}

export default Events